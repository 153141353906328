import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Translation from "../../language/translate";
import {
  bookADemo,
  bookADemoRepeatOrder,
  promoCodeReferrerStatus,
  NonKitchenBooking,
} from "../../actions/bookFreeConsultationActions";
import { message, Modal, Button } from "antd";
import Map from "../book-demo-google-map/Map";
import { ClipLoader } from "react-spinners";
import moment from "moment";
import { gaPageView, gaEvent } from "../../googleAnalytics";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import "./index.css";
import swal from "sweetalert";
import {
  SMEvent,
  SMEvent_Booked_A_Demo,
  SMEvent_P_Booking_started,
} from "../../smartlook";
import { useLocation } from "react-router";

const ConsultationMeetLocation = (props) => {
  const houseNumberRef = useRef(null);
  const landmarkRef = useRef(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const referralCode = params.get("referralCode");

  const referenceCodeRef = useRef(null);
  const dispatch = useDispatch();
  const details = useSelector((state) => state);
  const [choseContentLength, setChoseContentLength] = useState([]);
  const [loader, setLoader] = useState(false);
  const [meetingAddress, setMeetingAddress] = useState(
    localStorage.getItem("location_address")
      ? localStorage.getItem("location_address")
      : props.orderData.meeting_address
  );
  const [meetingCountry, setMeetingCountry] = useState(
    localStorage.getItem("location_country")
      ? localStorage.getItem("location_country")
      : props.orderData.country
  );
  const [refernceCode, setRefernceCode] = useState(props.referal_code);
  const [referenceError, setReferenceError] = useState(false);
  const [meetingCity, setMeetingCity] = useState(
    localStorage.getItem("location_city")
      ? localStorage.getItem("location_city")
      : props.orderData.city
  );
  const [meetingLatitude, setMeetingLatitude] = useState(
    localStorage.getItem("location_lat")
      ? localStorage.getItem("location_lat")
      : props.orderData.latitude
  );
  const [meetingLongitude, setMeetingLongitude] = useState(
    localStorage.getItem("location_long")
      ? localStorage.getItem("location_long")
      : props.orderData.longitude
  );
  const [choseContent, setChoseContent] = useState([]);
  const { confirm } = Modal;

  useEffect(() => {
    onLoad();
    setMeetingCity(
      localStorage.getItem("location_city")
        ? localStorage.getItem("location_city")
        : props.orderData.city
    );
    setMeetingCountry(
      localStorage.getItem("location_country")
        ? localStorage.getItem("location_country")
        : props.orderData.meeting_address
    );
    if (referralCode) {
      referenceCodeRef.current.value = referralCode;
    }
  }, []);

  const onChangeSite = (e) => {
    if (e == "New Site" || e == "new_site") {
      return "جديد";
    } else return "قديم";
  };

  const onChangeRange = (value) => {
    if (value == "Economical") return "اقتصادي";
    else if (value == "Moderate") return "متوسط ";
    else if (value == "Premium") return "عالي ";
    else return "أي ميزانية, (غير مقيد الميزانية)";
  };

  const onLoad = () => {
    // booking new changes starts here
    //   if(details.nonKitchen.nonkitchen.service != undefined){
    //     let choseContent = [
    //         details.nonKitchen.nonkitchen.service? details.nonKitchen.nonkitchen.service : "-",
    //         details.nonKitchen.nonkitchen.kitchenOrder? `Kitchen Order Id :${details.nonKitchen.nonkitchen.kitchenOrder[0]}` : "No Kitchen order attached",
    //         moment(details.meetDateAndTime.meetDateAndTime.date).format(
    //           "MMM DD YYYY"
    //         ) +
    //           ", " +
    //           details.meetDateAndTime.meetDateAndTime.time,
    //     ]
    //     let choseContentArabic = [
    //         details.nonKitchen.nonkitchen.serviceArabic? details.nonKitchen.nonkitchen.serviceArabic : "-",
    //         details.nonKitchen.nonkitchen.kitchenOrder? `Kitchen Order Id :${details.nonKitchen.nonkitchen.kitchenOrder[0]}` : "No Kitchen order attached",
    //         moment(details.meetDateAndTime.meetDateAndTime.date).format(
    //           "MMM DD YYYY"
    //         ) +
    //           ", " +
    //           details.meetDateAndTime.meetDateAndTime.time,
    //     ]
    //     setChoseContent(details.Languages == "en"? choseContent: choseContentArabic)
    //     setChoseContentLength(choseContent.length)
    // } else {
    //   let choseContent = [
    //     details.designKitchen.kitchenSizeShape.shape? details.designKitchen.kitchenSizeShape.shape : props.orderData.kitchen_shape_title,
    //     details.designKitchen.kitchenSizeShape.size && details.designKitchen.kitchenSizeShape.sizeUnit? details.designKitchen.kitchenSizeShape.size +
    //       " " +
    //       details.designKitchen.kitchenSizeShape.sizeUnit +
    //       " " +
    //       "(Kitchen size)" : props.orderData.kitchen_size + " " + props.orderData.kitchen_size_dimension +" " +"(Kitchen size)",
    //     details.chooseStyle.kitchenStyleSelected? details.chooseStyle.kitchenStyleSelected : props.orderData.kitchen_style_category_name,
    //     details.moreInformation.moreInformation.site? details.moreInformation.moreInformation.site == "New Site" || details.moreInformation.moreInformation.site == "new_site"? "New Site" : "Old Site" : props.orderData.site_type == "New Site" || props.orderData.site_type == "new_site"? "New Site" : "Old Site",
    //     details.moreInformation.moreInformation.priceRange? details.moreInformation.moreInformation.priceRange : props.orderData.budget_range_type,
    //     details.moreInformation.moreInformation.days? details.moreInformation.moreInformation.days : (props.orderData.project_start_expected_by).split(" ")[0],
    //     moment(details.meetDateAndTime.meetDateAndTime.date).format(
    //       "MMM DD YYYY"
    //     ) +
    //       ", " +
    //       details.meetDateAndTime.meetDateAndTime.time,
    //   ];
    //   let choseContentArabic = [
    //     details.designKitchen.kitchenSizeShape.shapeArabic? details.designKitchen.kitchenSizeShape.shapeArabic : props.orderData.kitchen_shape_title_arabic,
    //     details.designKitchen.kitchenSizeShape.size && details.designKitchen.kitchenSizeShape.sizeUnit? details.designKitchen.kitchenSizeShape.size +
    //       " " +
    //       details.designKitchen.kitchenSizeShape.sizeUnit +
    //       " " +
    //       "(مقاس المطبخ)" : props.orderData.kitchen_size + " " + props.orderData.kitchen_size_dimension +" " +"(مقاس المطبخ)",
    //     details.chooseStyle.kitchenStyleSelectedArabic? details.chooseStyle.kitchenStyleSelectedArabic : props.orderData.kitchen_style_category_name_arabic,
    //     details.moreInformation.moreInformation.siteInfoArabic? details.moreInformation.moreInformation.siteInfoArabic : onChangeSite(props.orderData.site_type),
    //     details.moreInformation.moreInformation.priceRangeArabic? details.moreInformation.moreInformation.priceRangeArabic : onChangeRange(props.orderData.budget_range_type),
    //     details.moreInformation.moreInformation.days?
    //       details.moreInformation.moreInformation.days : (props.orderData.project_start_expected_by).split(" ")[0],
    //     moment(details.meetDateAndTime.meetDateAndTime.date).format(
    //       "MMM DD YYYY"
    //     ) +
    //       ", " +
    //       details.meetDateAndTime.meetDateAndTime.time,
    //   ];
    //   setChoseContent(
    //     details.Languages == "en" ? choseContent : choseContentArabic
    //   );
    //   setChoseContentLength(choseContent.length);
    // }
    // booking new changes ends here
  };

  const locationFetch = (address, country, city, coordinates) => {
    if (country) {
      setMeetingCountry(country);
    } else {
      setMeetingCountry("");
    }
    if (city) {
      setMeetingCity(city);
    } else {
      setMeetingCity("");
    }
    setMeetingAddress(address);
    setMeetingLatitude(coordinates.lat);
    setMeetingLongitude(coordinates.lng);
  };

  function countDown() {
    Modal.info({
      title: Translation[details.Languages]["Bookingblocked"],
      onOk() {
        props.handleClose();
      },
    });
  }

  function countDownoutside() {
    Modal.info({
      title: Translation[details.Languages]["Bookingoutsidecountry"],
      onOk() {
        props.handleClose();
      },
    });
  }

  const referrerCodePromo = async () => {
    if (props.orderData && props.orderData.referrer_discount_code) {
      let obj = {
        _id: props.orderData && props.orderData.referrer_id,
        referrer_code:
          props.orderData && props.orderData.referrer_discount_code,
        token: localStorage.getItem("user_token"),
      };

      const referrerCodeResponse = await dispatch(promoCodeReferrerStatus(obj));

      if (referrerCodeResponse.data.message.active == true) {
        meetLocationContinueButton();
      } else {
        swal({
          title: Translation[details.Languages]["promocodeAlert"],
          text: Translation[details.Languages]["promocodealertmes"],
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            meetLocationContinueButton();
          } else {
            swal(Translation[details.Languages]["promocodeCanceled"]);
          }
        });
      }
    } else {
      meetLocationContinueButton();
    }
  };

  const meetLocationContinueButton = async () => {
    setLoader(true);
    let fetchdate = moment().format("YYYY-MM-D h:mm:ss");
    // console.log(fetchdate);
    //    if(fetchedLocation.longitude){
    let obj = {
      customer_id: localStorage.getItem("customer_id"),
      kitchen_size: details.designKitchen.kitchenSizeShape.size
        ? details.designKitchen.kitchenSizeShape.size
        : props.orderData.kitchen_size,
      kitchen_size_dimension: details.designKitchen.kitchenSizeShape.sizeUnit
        ? details.designKitchen.kitchenSizeShape.sizeUnit
        : props.orderData.kitchen_size_dimension,
      kitchen_shape: details.designKitchen.kitchenSizeShape.shapeId
        ? details.designKitchen.kitchenSizeShape.shapeId
        : props.orderData.kitchen_shape,
      kitchen_style: details.chooseStyle.styleId
        ? details.chooseStyle.styleId
        : props.orderData.kitchen_style,
      site_type: details.moreInformation.moreInformation.site
        ? details.moreInformation.moreInformation.site == "New Site" ||
          details.moreInformation.moreInformation.site == "new_site"
          ? "New Site"
          : "Old Site"
        : props.orderData.site_type == "New Site" ||
          props.orderData.site_type == "new_site"
        ? "New Site"
        : props.orderData.site_type == "Old Site" ||
          props.orderData.site_type == "old_site"
        ? "Old Site"
        : "",
      order_type: "kitchen",
      budget_range_type: details.moreInformation.moreInformation.priceRange
        ? details.moreInformation.moreInformation.priceRange
        : props.orderData.budget_range_type,
      // "budget_range_amount": details.moreInformation.moreInformation.priceRange.split(" (")[0],
      budget_range_amount: "-",
      project_start_expected_by: details.moreInformation.moreInformation.days
        ? details.moreInformation.moreInformation.days + " months"
        : props.orderData.project_start_expected_by,
      meeting_date_and_time:
        details.meetDateAndTime.meetDateAndTime.date.toISOString(),
      meeting_time: details.meetDateAndTime.meetDateAndTime.time,
      meeting_address: meetingAddress,
      longitude: parseFloat(meetingLongitude),
      latitude: parseFloat(meetingLatitude),
      referal_code: referenceCodeRef.current.value,
      country: meetingCountry,
      city: meetingCity,
      // building_door_no: houseNumberRef.current.value,
      // landmark: landmarkRef.current.value,
      token: localStorage.getItem("user_token"),
      language: details.Languages,
      order_date: fetchdate,
      reference_order_id:
        props.repeatOrder == true ? props.orderData.reference_order_id : "",
      reference_id: props.repeatOrder == true ? props.orderData._id : "",
      booking_platform: "web",
    };
    if (obj.country === "" || obj.city === "") {
      const key = "updatable";
      message.warning({
        content:
          Translation[details.Languages][
            "ServiceNotAvaliableInCurrentLocation"
          ],
        key,
        duration: 5,
      });
      return setLoader(false);
    }

    const bookADemoResponse =
      props.repeatOrder == true
        ? await dispatch(bookADemoRepeatOrder(obj))
        : await dispatch(bookADemo(obj));
    // const bookADemoResponse = await dispatch(bookADemo(obj));
    // console.log(bookADemoResponse, "bookADemoResponse");
    if (bookADemoResponse.data.status_code == 200) {
      setReferenceError(false);
      dispatch({
        type: "BOOK_A_DEMO_RESPONSE",
        data: {
          meetingCountry: meetingCountry,
          meetingLongitude: parseFloat(meetingLongitude),
          meetingLatitude: parseFloat(meetingLatitude),
          meetingCity: meetingCity,
          orderID: bookADemoResponse.data.order_details[0]._id,
          customerDetails:
            bookADemoResponse.data.order_details[0].customer_details,
        },
      });
      props.handleNext();
      localStorage.setItem("setnotificationcount", "true");
      localStorage.removeItem("campaignUser");
      gaEvent(
        "accepted_new_order",
        "Launch order from website",
        "Website orderaccepted"
      );
      SMEvent(SMEvent_Booked_A_Demo, SMEvent_P_Booking_started);
    } else if (bookADemoResponse.data.status_code == 201) {
      let count = 0;
      localStorage.setItem("counter", count);
      localStorage.removeItem("campaignUser");
      // console.log(localStorage.getItem("counter"));
      setReferenceError(false);
      dispatch({
        type: "BOOK_A_DEMO_RESPONSE",
        data: {
          meetingCountry: meetingCountry,
          meetingLongitude: parseFloat(meetingLongitude),
          meetingLatitude: parseFloat(meetingLatitude),
          meetingCity: meetingCity,
          orderID: bookADemoResponse.data.order_details[0]._id,
          customerDetails:
            bookADemoResponse.data.order_details[0].customer_details,
        },
      });
      props.handleNext("noDesigner");
      gaEvent(
        "accepted_new_order",
        "Launch order from website",
        "Website orderaccepted"
      );
      SMEvent(SMEvent_Booked_A_Demo, SMEvent_P_Booking_started);
    } else if (bookADemoResponse.data.status_code == 403) {
      const key = "updatable";
      message.warning({
        content: Translation[details.Languages]["DesignerSlotError"],
        key,
        duration: 5,
      });
    } else if (bookADemoResponse.data.status_code == 405) {
      setReferenceError(true);
    } else if (bookADemoResponse.data.status_code == 406) {
      countDown();
    } else if (bookADemoResponse.data.status_code == 407) {
      const key = "updatable";
      message.warning({
        content:
          Translation[details.Languages][
            "ServiceNotAvaliableInCurrentLocation"
          ],
        key,
        duration: 5,
      });
    } else if (bookADemoResponse.data.status_code == 202) {
      countDownoutside();
      gaEvent(
        "new_order_outside_Riyadh",
        "Launch order from website",
        "Website orderrejected"
      );
      SMEvent(SMEvent_Booked_A_Demo, SMEvent_P_Booking_started);
    }
    setLoader(false);
  };

  const meetLocationNonKitchenContinueButton = async () => {
    setLoader(true);
    let fetchdate = moment().format("YYYY-MM-D h:mm:ss");
    //    if(fetchedLocation.longitude){
    let obj;
    if (details.nonKitchen.nonkitchen.kitchenOrder) {
      obj = {
        parent_order_id: details.nonKitchen.nonkitchen.kitchenOrder[1],
        customer_id: localStorage.getItem("customer_id"),
        order_type: details.nonKitchen.nonkitchen.serviceCode,
        meeting_date_and_time:
          details.meetDateAndTime.meetDateAndTime.date.toISOString(),
        meeting_time: details.meetDateAndTime.meetDateAndTime.time,
        meeting_address: meetingAddress,
        longitude: parseFloat(meetingLongitude),
        latitude: parseFloat(meetingLatitude),
        referal_code: details.nonKitchen.nonkitchen.kitchenOrder
          ? details.nonKitchen.nonkitchen.kitchenOrder[2]
          : referenceCodeRef.current.value
          ? referenceCodeRef.current.value
          : "",
        order_date: fetchdate,
        booking_platform: "web",
        booking_platform_os: "web",
        country: meetingCountry,
        city: meetingCity,
        token: localStorage.getItem("user_token"),
      };
    } else {
      obj = {
        customer_id: localStorage.getItem("customer_id"),
        order_type: details.nonKitchen.nonkitchen.serviceCode,
        meeting_date_and_time:
          details.meetDateAndTime.meetDateAndTime.date.toISOString(),
        meeting_time: details.meetDateAndTime.meetDateAndTime.time,
        meeting_address: meetingAddress,
        longitude: parseFloat(meetingLongitude),
        latitude: parseFloat(meetingLatitude),
        referal_code: details.nonKitchen.nonkitchen.kitchenOrder
          ? details.nonKitchen.nonkitchen.kitchenOrder[2]
          : referenceCodeRef.current.value
          ? referenceCodeRef.current.value
          : "",
        order_date: fetchdate,
        booking_platform: "web",
        booking_platform_os: "web",
        country: meetingCountry,
        city: meetingCity,
        token: localStorage.getItem("user_token"),
      };
    }

    if (obj.country === "" || obj.city === "") {
      const key = "updatable";
      message.warning({
        content:
          Translation[details.Languages][
            "ServiceNotAvaliableInCurrentLocation"
          ],
        key,
        duration: 5,
      });
      return setLoader(false);
    }
    const bookADemoResponse = await dispatch(NonKitchenBooking(obj));
    // const bookADemoResponse = await dispatch(bookADemo(obj));
    // console.log(bookADemoResponse, "bookADemoResponse");
    if (bookADemoResponse.data.status_code == 200) {
      setReferenceError(false);
      dispatch({
        type: "BOOK_A_DEMO_RESPONSE",
        data: {
          meetingCountry: meetingCountry,
          meetingLongitude: parseFloat(meetingLongitude),
          meetingLatitude: parseFloat(meetingLatitude),
          meetingCity: meetingCity,
          orderID: bookADemoResponse.data.order_details[0]._id,
          customerDetails:
            bookADemoResponse.data.order_details[0].customer_details,
        },
      });
      props.handleNext();
      localStorage.setItem("setnotificationcount", "true");
      gaEvent(
        "accepted_new_order",
        "Launch order from website",
        "Website orderaccepted"
      );
      localStorage.removeItem("parent_Kitchen_order");
      localStorage.removeItem("campaignUser");
    } else if (bookADemoResponse.data.status_code == 201) {
      let count = 0;
      localStorage.setItem("counter", count);
      localStorage.removeItem("campaignUser");
      // console.log(localStorage.getItem("counter"));
      setReferenceError(false);
      dispatch({
        type: "BOOK_A_DEMO_RESPONSE",
        data: {
          meetingCountry: meetingCountry,
          meetingLongitude: parseFloat(meetingLongitude),
          meetingLatitude: parseFloat(meetingLatitude),
          meetingCity: meetingCity,
          orderID: bookADemoResponse.data.order_details[0]._id,
          customerDetails:
            bookADemoResponse.data.order_details[0].customer_details,
        },
      });
      props.handleNext("noDesigner");
      gaEvent(
        "accepted_new_order",
        "Launch order from website",
        "Website orderaccepted"
      );
    } else if (bookADemoResponse.data.status_code == 403) {
      const key = "updatable";
      message.warning({
        content: Translation[details.Languages]["DesignerSlotError"],
        key,
        duration: 5,
      });
    } else if (bookADemoResponse.data.status_code == 407) {
      const key = "updatable";
      message.warning({
        content:
          Translation[details.Languages][
            "ServiceNotAvaliableInCurrentLocation"
          ],
        key,
        duration: 5,
      });
    } else if (bookADemoResponse.data.status_code == 405) {
      setReferenceError(true);
    } else if (bookADemoResponse.data.status_code == 406) {
      countDown();
    } else if (bookADemoResponse.data.status_code == 202) {
      countDownoutside();
      gaEvent(
        "new_order_outside_Riyadh",
        "Launch order from website",
        "Website orderrejected"
      );
    }
    setLoader(false);
  };

  return (
    <React.Fragment>
      <div
        className="ready-to-meet-wrap"
        align={details.Languages == "ar" ? "right" : ""}
      >
        <div className="header-title">
          {Translation[details.Languages]["DeliveryFittingAddress"]}
        </div>
        <div className="header-description">
          {Translation[details.Languages]["DeliveryFittingDes"]}
        </div>
        <div className="row" style={{ padding: "60px 0px" }}>
          <div className="col-lg-12 col-md-12 col-sm-12">
            {/* <div className="col-lg-8 col-md-12 col-sm-12"> */}
            <div className="meet-info-class location-meet-up">
              {details.Languages == "en" ? (
                <i
                  className="fa fa-long-arrow-right long-arrow-header"
                  aria-hidden="true"
                ></i>
              ) : (
                ""
              )}
              {Translation[details.Languages]["LocationToMeet"]}
              {details.Languages == "ar" ? (
                <i
                  className="fa fa-long-arrow-left long-arrow-left-header-location"
                  aria-hidden="true"
                ></i>
              ) : (
                ""
              )}
            </div>
            <div>
              <Map
                google={props.google}
                center={{
                  lat: parseFloat(localStorage.getItem("location_lat")),
                  lng: parseFloat(localStorage.getItem("location_long")),
                }}
                height="300px"
                zoom={15}
                locationFetch={locationFetch}
              />
            </div>
            {/* <div>
              <input
                className={
                  details.Languages == "en"
                    ? "location-search"
                    : "location-searchar"
                }
                placeholder={Translation[details.Languages]["Houseno"]}
                ref={houseNumberRef}
              />
            </div> */}
            {/* <div>
              <input
                className={
                  details.Languages == "en"
                    ? "location-search"
                    : "location-searchar"
                }
                placeholder={
                  Translation[details.Languages]["DeliveryFittingLandmark"]
                }
                ref={landmarkRef}
              />
            </div> */}
            <div className="meet-info-class location-meet-up">
              {details.Languages == "en" ? (
                <i
                  className="fa fa-long-arrow-right long-arrow-header"
                  aria-hidden="true"
                ></i>
              ) : (
                ""
              )}

              {details.nonKitchen.nonkitchen.kitchenOrder ? (
                ""
              ) : (
                <span>
                  {Translation[details.Languages]["ReferenceCode"]}
                  {details.Languages == "ar" ? (
                    <i
                      className="fa fa-long-arrow-left long-arrow-left-header-location"
                      aria-hidden="true"
                    ></i>
                  ) : (
                    ""
                  )}
                </span>
              )}
            </div>

            {details.nonKitchen.nonkitchen.kitchenOrder ? (
              ""
            ) : (
              <span>
                <input
                  type="text"
                  ref={referenceCodeRef}
                  defaultValue={
                    details.nonKitchen.nonkitchen.kitchenOrder
                      ? details.nonKitchen.nonkitchen.kitchenOrder[2]
                      : props.orderData && props.orderData.referal_code
                      ? props.orderData.referal_code
                      : referralCode
                  }
                  disabled={props.orderData && props.orderData.referal_code}
                  style={
                    details.Languages == "ar"
                      ? { width: "100%", paddingRight: "15px" }
                      : { width: "100%", paddingLeft: "15px" }
                  }
                  placeholder={Translation[details.Languages]["RefernceCode"]}
                  className="refernce-code"
                />
                {referenceError ? (
                  <span className="error-div">Reference code is not valid</span>
                ) : (
                  ""
                )}
              </span>
            )}
          </div>

          {/* booking new changes comment starts here */}

          {/* <div className="col-lg-4 col-md-12 col-sm-12">
            <div className="you-chose-card-info-screen">
              <div className="you-chose-title">
                {Translation[details.Languages]["YouChose"]}
                <span className="you-chose-edit" onClick={props.handlePreviousReadyToMeet}><i className="fa fa-pencil-square-o"></i>{Translation[details.Languages]["Edit"]}</span>
              </div>
              <ul
                className={
                  details.Languages == "en"
                    ? "chose-info"
                    : "chose-info chose-info-arabic"
                }
              >
                {choseContent.map((list, i) =>
                  choseContentLength - 2 === i ? (
                    <li>
                      {details.Languages == "en" ? (
                        <i
                          className="fa fa-long-arrow-right long-arrow-class-more-info"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        ""
                      )}
                      {details.nonKitchen.nonkitchen.service != undefined? list :details.Languages == "en"
                        ? "Need by " + list + " months"
                        : "تحتاج من قبل " + list + " الشهور"}
                      {details.Languages == "ar" ? (
                        <i
                          className="fa fa-long-arrow-left long-arrow-left-class-more-info"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        ""
                      )}
                    </li>
                  ) : choseContentLength === i + 1 ? (
                    <li>
                      {details.Languages == "en" ? (
                        <i
                          className="fa fa-long-arrow-right long-arrow-class-more-info"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        ""
                      )}
                      {details.nonKitchen.nonkitchen.service != undefined? list : details.Languages == "en" ? "on " + list : "على " + list}
                      {details.Languages == "ar" ? (
                        <i
                          className="fa fa-long-arrow-left long-arrow-left-class-more-info"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        ""
                      )}
                    </li>
                  ) : (
                    <li>
                      {details.Languages == "en" ? (
                        <i
                          className="fa fa-long-arrow-right long-arrow-class-more-info"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        ""
                      )}
                      {list}
                      {details.Languages == "ar" ? (
                        <i
                          className="fa fa-long-arrow-left long-arrow-left-class-more-info"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        ""
                      )}
                    </li>
                  )
                )}
              </ul>
            </div>
          </div> */}

          {/* booking new changes comment ends here */}
        </div>
        {loader == true ? (
          <div style={{ width: "100%", textAlign: "center" }}>
            <ClipLoader color={"#242E49"} loading={loader} />
          </div>
        ) : (
          <div className="row button-wrap">
            <div className="col-lg-6 col-md-6 col-sm-12 go-back-wrap">
              <div
                className={
                  details.Languages == "en"
                    ? "go-back-button"
                    : "go-back-button float-left-arabic resp_gobkbtn"
                }
                onClick={props.handlePrevious}
              >
                {Translation[details.Languages]["GoBackButton"]}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 continue-wrap">
              <input
                class="continue-button"
                type="button"
                // onClick={meetLocationContinueButton}
                onClick={
                  details.nonKitchen.nonkitchen.service != undefined
                    ? meetLocationNonKitchenContinueButton
                    : props.repeatOrder == true
                    ? referrerCodePromo
                    : meetLocationContinueButton
                }
                value={
                  Translation[details.Languages]["ConfirmYourBookingButton"]
                }
              />
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ConsultationMeetLocation;
